.Select {
  &.is-focused {
    .Select-control {
      border-color: darken(#dfe7eb, 10%);
      border-radius: 3px;
    }
  }

  &.is-disabled > .Select-control {
    background-color: #f8f8f8;
    border: 1px solid #f3f3f8;
  }

  .Select-control,
  .Select-menu-outer {
    font-size: 14px;
    font-weight: 400;
  }

  .Select-control {
    border: solid 1px #dfe7eb;
    border-radius: 3px;
  }

  .Select-control:hover {
    box-shadow: none;
  }

  .Select-menu-outer {
    z-index: 100;

    margin-top: 1px;
    border: 0;
    border-radius: 4px;

    box-shadow: 0 16px 26px 0 #dfe7eb;
  }

  .Select-menu {
    border-radius: 4px;
    background: #f8fafb;
  }

  .Select-value {
    border: 1px solid #e9f0fb;
    background-color: #e9f0fb;
    border-radius: 5px;
    color: #3861bf;
    font-size: 14px;
    font-weight: 400;
  }

  .Select-value-icon {
    border: 0;
  }

  .Select-option,
  .Select-noresults {
    font-size: 14px;
    font-weight: 400;
  }

  .Select-placeholder {
    font-size: 14px;
    font-weight: 400;
    color: var(--input-placeholder-color);
    pointer-events: none;
  }

  &.Select--single .Select-value {
    background: #fff;
    border: 0;
  }

  .Select-input > input {
    min-width: 180px;
    padding: 8px 0;
  }
}
