$header-height: 44px;
$content-height: calc(100vh - #{$header-height});

$color-primary: #2bbbbb;
$color-primary-dark: darken($color-primary, 20%);
$color-secondary: #400099;
$color-tertiary: #f45da9;

$color-primary-light: #d3f7f7;
$color-utility-pink: #fdeef6;
$color-dark-cool-grey: #333f52;
$color-mid-cool-grey: #637280;
$color-cool-blue-grey: #c5d0de;

$color-background-light: #f5f5f5;
$color-background-hover: #898989;

$color-text-placeholder: #aab7c4;
$color-text-dark: #353a46;
$color-text-light: #6b7785;
$color-input-border: #dfe7eb;

$color-button-background: #f9f9f9;

$color-alert-red: #ff4743;
$color-alert-red-light: lighten($color-alert-red, 30%);
$color-alert-yellow: #ff9e00;
$color-alert-yellow-light: lighten($color-alert-yellow, 38%);

$color-positive-green: #2dc76d;
$color-negative-red: #ff7052;

$mid-gray: #76838f;

$z-index-component: 100;
$z-index-overlay: 200;
$z-index-header: 300;
$z-index-modal: 400;
