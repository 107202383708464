@import './variables';

::-webkit-input-placeholder {
  font-weight: 400;
  color: $color-text-placeholder;
}

::-moz-placeholder {
  font-weight: 400;
  color: $color-text-placeholder;
}

:-ms-input-placeholder {
  font-weight: 400;
  color: $color-text-placeholder;
}

:-moz-placeholder {
  font-weight: 400;
  color: $color-text-placeholder;
}

::-moz-selection {
  background-color: #b2d6ff;
}

::selection {
  background-color: #b2d6ff;
}
