@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Avenir-Light.woff2') format('woff2'),
    url('../fonts/Avenir-Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Avenir-Medium.woff2') format('woff2'),
    url('../fonts/Avenir-Medium.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/Avenir-Heavy.woff2') format('woff2'),
    url('../fonts/Avenir-Heavy.woff') format('woff');
  font-weight: 500;
}
