$header-height: 44px;
$content-height: calc(100vh - #{$header-height});

$spacing-base: 16px;
$spacing-xs: $spacing-base * 0.25;
$spacing-sm: $spacing-base * 0.5;
$spacing-md: $spacing-base;
$spacing-lg: $spacing-base * 1.5;
$spacing-xl: $spacing-base * 2;

$color-primary: #2bbbbb;
$color-secondary: #400099;
$color-tertiary: #f45da9;

$color-white: #fff;
$color-primary-light: #d3f7f7;
$color-utility-pink: #fdeef6;
$color-dark-cool-grey: #333f52;
$color-mid-cool-grey: #637280;
$color-cool-blue-grey: #c5d0de;
$color-cool-blue-white: #ecf2f4;
$color-light-blue: #319bf5;
$color-athena-blue: #1e2d88;

$color-background-light: #f5f5f5;
$color-background-hover: #898989;

$color-text-placeholder: #aab7c4;
$color-text-dark: #353a46;
$color-text-light: #6b7785;
$color-input-border: #dfe7eb;

$color-button-background: #f9f9f9;

$color-alert-red: #ff3b2f;
$color-alert-yellow: #ff9e00;

$color-positive-green: #2dc76d;
$color-negative-red: #ff7052;

$color-confirmation-green: #4bd963;
$color-background-green: #ddf4e2;
$color-type-green: #318e45;

$color-background-turquoise: #dff6fa;
$color-type-turquoise: #005870;

$mid-gray: #76838f;
$light-gray: #bcc2c6;

$color-error-panel-background: #f8fafb;
$color-error-panel-border: #edf2f4;

$color-grey-border: #d7dadc;

$z-index-component: 100;
$z-index-overlay: 200;
$z-index-header: 300;
$z-index-modal: 400;

:root {
  --brand-color-turquoise: #1bb4d0;
  --brand-color-purple: #400099;
  --brand-color-pink: #ff518a;
  --brand-color-orange: #ff9e01;
  --brand-color-blue: #4481ff;
  --brand-color-dark-turquoise: #00829a;
  --brand-color-dark-pink: #db3068;
  --brand-color-dark-orange: #cb4e00;
  --brand-color-dark-blue: #3670e9;
}
