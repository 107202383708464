// These breakpoints should be kept in sync with the breakpoints in ~/ui/Responsive
// See that folder's documentation for more information

@mixin mobile {
  @media (max-width: 649px) {
    @content;
  }
}

@mixin above-mobile {
  @media (min-width: 650px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 650px) and (max-width: 999px) {
    @content;
  }
}

@mixin below-desktop {
  @media (max-width: 999px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1000px) {
    @content;
  }
}
